import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Section1 from "../sections/sign-up-for-mentorship-section4";

const FifthSectionBackground = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:991px){
    min-height: 632px;
  }
`
const FirstRow = styled(Row)`
  padding-top:223px;
  min-height:835px;
  @media(max-width:991px){
    padding-top:120px;
    min-height:700px;
  }
  @media(max-width:767px){
    padding-top:210px;
  }
`

const Tagline = styled.div`
  color: ${props => props.theme.colors.secondary_green};
  font: 28px/32px Abril Fatface;
  @media(min-width:768px){
    width: 376px;
  }
  @media(max-width:767px){
    margin-top: 20px;
  }
  @media(max-width:320px){
    font: 25px/30px Abril Fatface;
  }
`
const BgCol5 = styled(Col)`
  :before {
    background-image:url('/page-home-section5-bg2.png');
    content: '';
    display: block;
    position: absolute;
    width: 474px;
    height: 400px;
    top: 112px;
    right: 316px;
    z-index:1;
  }
  :after {
    background-image:url('/page-home-section5-bg1.png');
    content: '';
    display: block;
    position: absolute;
    width: 418px;
    height: 418px;
    top: -123px;
    left: -400px;
    z-index:2;
  }
  @media(max-width:991px){
    :after {
      display:none;
    }
    :before {
      right:150px;
    }
  }
  @media(max-width:767px){
    :before {
      display:none;
    }
    :after {
      display:block;
      width: 300px;
      height: 300px;
      top: -415px;
      left: 73px;
      background-position:center;
    }
  }
  @media(max-width:374px){
    :after {
      width: 280px;
    }
  }
  @media(max-width:359px){
    :after {
      width: 240px;
    }
  }
`

const FifthSectionCarousel = styled(Carousel)`
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.primary_violet};;
  }
  .carousel-indicators {
    position: absolute;
    top: -110px;
    left: 0;
    margin-left: 0;
    justify-content: left;
    @media(max-width:375px) {
      top: -130px;
    }
  }
  .carousel-inner {
    min-height:375px;
    margin-bottom:0;
    @media(max-width:320px) {
      min-height:420px;
    }
  }
  .carousel-item {
    .alt-row {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-right: -15px !important;
      margin-left: -15px !important;
    }
  }
`

const Section1Wrapper = styled.div`
  display: ${props => props.DisplaySection};
`

let Testimonials = [
  {content: (<div>“I’m glad I signed up for Women Works as I realised the power of having a mentor as a startup founder. My mentor shared the successes and failures she experienced and what she learned from it. <b>The perspective I’ve gained through the mentoring session allowed me to see the problem under a different light.</b>”</div>), 
    name: "Stephanie Seputra", 
    title: "Founder of Edusolve", 
    image:"/testimonials/stephanie-seputra.png"},
  {content: (<div>“My mentor provided me with <b>many practical tips for my MBA interview</b> (STAR, gesture, and how to grab the interviewer's attention). Thanks to Clairine and WomenWorks, I aced my MBA Interview! Now I'm looking to get promoted in my current workplace and get a scholarship for my dream school. I would love to get another session with WomenWorks to get guidance on how to boost performance and build excellence in strategy, execution, and teamwork.”</div>), 
    name: "Raihan Zahirah", 
    title: "Incoming VE Advisory - Capital Project & Infrastructure (CP&I)",
    image: "/testimonials/raihan-zahirah.png"},
  {content: (<div>“I truly <b>didn’t expect to get so much out of the one hour conversation I had with my mentor</b>. She answered most of the questions that I asked by sharing her experiences, showing me the management tools I could use to solve my problems, and even giving me book recommendations. By the end of the session, <b>I had a list of actionable items that I could implement to my business.</b>”</div>), 
    name: "Jessica Casey Jaya", 
    title: "COO of Apiary Co-Working Space, Startup Grindr Director Jakarta Chapter",
    image: ""},
  {content: (<div>“One word to describe my experience with Womenworks: '<b>Rewarding</b>'. Before, I used to be hesitant to reach out directly to a prospective mentor. Through the mentorship program, I got connected to a mentor that is well-suited for me. She provides actionable advice that would help me to reach my goal. <b>Although much information is now just one click away, learning from her experiences still gives me the knowledge that is not available elsewhere.</b>”</div>), 
    name: "Ivana Andrea Nolani", 
    title: "Ex-Special Projects Intern at OVO (PT Visionet Internasional) | Engineering Student at The Chinese University of Hong Kong",
    image: ""},
  {content: (<div>“I knew WomenWorks through a friend’s IG story and saw WomenWork’s empowered, badass, and financially independent tagline. The tagline was so eye-catching that I instantly followed the account. After a free and insightful 30-min pre-mentoring session, I decided to sign up. <b>I am thrilled with the mentor choice that WomenWorks gave me </b> since my mentor was also a woman in product and had been building great products from scratch. ”</div>), 
    name: "Seli Ballo", 
    title: "Product Manager at Tokopedia",
    image: ""},
  {content: (<div>“<b>My mentor is very persistent and checks on me every now and then to make sure I am on the right track.</b> I feel very grateful that she is willing to share her knowledge and experience. I hope that she will continue to help more people along her journey.”</div>), 
    name: "Ayu Tresna Ekadewi", 
    title: "Senior Consultant in Fashion & Retail. Brand : Kalyana, Priyanka, Motherclub, La karina, LovenBath, Aria, BAM, Dolci Somnii",
    image: ""},
  {content: (<div>“My mentor was very helpful and insightful, she was able to enlighten me about my interest in changing lanes and starting over in the US with a focus in tech. <b>She provided me with a very unique perspective, on what it takes to change industries in a short amount of time.</b> Her advice was to further explore and dig deeper on available roles in the tech industry, so that I would be able to really understand what it is I wanted to immerse myself into.”</div>), 
    name: "Gini Arimbi", 
    title: "Project Manager at Royal HaskoningDHV",
    image: ""},
  {content: (<div>“For me, this mentorship helped me shape my career path through networking and guided me to reflect on what I need. My mentor gave me the encouragement to do what I love and not just work. She also encouraged me to look forward to my marketing skill rather than just focus on my research career. <b>Now, I have broadened my search and landed a job as a Social Media Marketing Specialist!</b>”</div>), 
    name: "Nastiti Dewanti", 
    title: "Social Media Marketing Specialist at Circa Ideaworks",
    image: ""},
  {content: (<div>“I was mentored by kak Metha from Sayurbox and she was so insightful. We started off by laying the foundation and then she can see where the problem lies. <b>She gave practical advice for my business and even gave me some book recommendations to read</b>. Glad to be connected to her through WomenWorks. Thank you very much!”</div>), 
    name: "Salma Effendi", 
    title: "Policy, Education, and Publicity Intern at AIPA Secretariat",
    image: "/testimonials/salma-effendi.png"},
  {content: (<div>“As a freshgrad, landing a job can be very difficult. I've tried multiple things without getting anywhere, and not knowing what's wrong demotivates me.<br></br>This changed when  I tried a Womenworks Mentorship session. I told my mentor about my problem, and <b>she immediately enlightened me on things I wouldn't have known from public research and shared her views from a "recruiter" perspective</b>.  Now, I have the confidence and the know-how to find a job. ”</div>), 
    name: "Realina Monika", 
    title: "Social Media Marketing Intern at Telkomsel ",
    image: ""},
];

let Title = "What the community is saying about WomenWorks:";

export default function IndexSection2(props) {
  if(props.Testimonials) Testimonials = props.Testimonials;
  if(props.Title) Title = props.Title;
  return (
  <FifthSectionBackground>
    <Container>
      <FirstRow>
        <Col xs="12" md="4" lg="6" css={css`z-index:3;`} >
          <img alt="WomenWorks - hygge, cozy, and comfortable" src="/page-home-section5-img1.png"></img>
        </Col>
        <BgCol5 md="1"></BgCol5>
        <Col md="7" lg="5">
            <Tagline css={css`height:auto;@media(max-width:767px){margin-top:80px;}`}>
              {Title}
            </Tagline>  
          <FifthSectionCarousel indicators={true} controls={false}>
            {Testimonials.map(testimonial => 
              <Carousel.Item>
                <p css={css`font: 16px/22px Roboto;margin-top:25px;margin-bottom:25px;@media(max-width:767px){margin-top:20px;margin-bottom:20px;}`}>
                  {testimonial.content}
                </p>
                {/* on production, somehow the first row chaned to divS, so additional row is added*/}
                      <div>
                        <div className="alt-row">
                          {testimonial.image && <Col xs="auto" css={css`padding-right:0;margin-bottom:20px;`}>
                            <img width="90px" height="90px" alt={"WomenWorks testimonial - " + testimonial.name} src={testimonial.image}></img>
                          </Col>}
                          <Col>
                              {testimonial.image 
                              ? <p css={css`font:16px/20px Roboto; margin-top:27px;margin-bottom:0;`}>{testimonial.name}</p>
                              : <p css={css`font:16px/20px Roboto; margin-bottom:0;`}>{testimonial.name}</p>}
                              <p css={css`font:Bold 16px/20px Roboto;`}>{testimonial.title}</p>
                          </Col>
                        </div>
                      </div>
              </Carousel.Item>
            )}
          </FifthSectionCarousel>
        </Col>
     </FirstRow>
    </Container>
      <Section1Wrapper DisplaySection={props.DisplaySection}>
        <Section1></Section1>
      </Section1Wrapper>
  </FifthSectionBackground>
  )
}